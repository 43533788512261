



































import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "payments"
})
export default class Payments extends mixins(LoadingMixin, CurrentUserMixin) {
  activeTab: string;
  constructor() {
    super();
    this.activeTab = this.$route.path;
  }
}
